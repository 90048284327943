const logotext = "Luke";
const meta = {
    title: "Luke Colvin",
    description: "Hey I’m Luke Colvin, Software Dev Student _ Currently going to school full time at Olivet Nazarene University",
};

const introdata = {
    title: "Hey, I’m Luke!",
    animated: {
        first: "I love to code",
        second: "I code stuff... sometimes...",
        third: "Where am I?",
        fourth: "I build websites",
        fifth: "I make stuff",
        sixth: "I strive to make an impact on the world",
        seventh: "Certified semicolon enthusiast",
        eighth: "Debugging is my cardio",
        ninth: "Will code for coffee",
        tenth: "404: Bio not found",
        eleventh: "I speak fluent pseudocode",
        twelfth: "I turn coffee into code",
    },
    // description: "Hello there! My name is Luke Colvin, I am  currently a Comp Sci student at Olivet Nazarene University. I'm passionate about technology and outreach for the community. I am actively involved in volunteer work as a web developer for the Center for Cybersecurity Outreach at ONU.",
    your_img_url: "",
};

const dataabout = {
    title: "A bit about me...",
    aboutme: "Hello there! My name is Luke Colvin, I am  currently a Comp Sci student at Olivet Nazarene University. I'm passionate about technology and outreach for the community. I am actively involved in volunteer work as a web developer for the Center for Cybersecurity Outreach at ONU.",
};
const worktimeline = [{
        jobtitle: "Web Application Developer",
        where: "Olivet Nazarene University",
        date: "Sep 2023 - Present",
    },
    // {
    //     jobtitle: "Web Developer",
    //     where: "CCO at ONU",
    //     date: "Aug 2023 - Present",
    // },
];

// added this to differentiate between work and volunteering
const volunteering = [{
        jobtitle: "Web Developer",
        where: "CCO at ONU",
        date: "Aug 2023 - Present",
    },
    // {
    //     jobtitle: "President",
    //     where: "CCO at ONU",
    //     date: "Aug 2024 - Present",
    // },
];

const school = [{
    name: "Olivet Nazarene University",
    // where: "Bourbonnais, IL",
    degree: "BS, Computer Science",
    gpa: "GPA: 4.0",
    minor: "Minor in Cybersecurity",      
    when: "2023 - Present",

    },
];

const skills = [
    {
        name: "PHP",
        value: 60,
    },
    {
        name: "Javascript",
        value: 80,
    },
    {
        name: "HTML",
        value: 80,
    },
    {
        name: "CSS",
        value: 80,
    },
    {
        name: "Python",
        value: 85,
    },
    {
        name: "SQL",
        value: 70,
    },
    {
        name: "Linux",
        value: 75,
    },
    {
        name: "Windows Server",
        value: 60,
    },
];

const skillsLearning = [
    {
        name: "C++",
        value: 40,
    },
    {
        name: "React",
        value: 30,
    },
    {
        name: "Java",
        value: 45,
    },
];

const services = [//{
    //     title: "UI & UX Design",
    //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    // },
    // {
    //     title: "Mobile Apps",
    //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    // },
    // {
    //     title: "Wordpress Design",
    //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    // },
];

const dataProjects = [{
        img: "https://github.com/lucolvin/public-pics-hosting/blob/main/images/project.jpg?raw=true",
        description: "One of my first projects, I created Tic Tac Toe in HTML, CSS, and Javascript.",
        link: "https://github.com/lucolvin/Tic-Tac-Toe",
    },
    {
        img: "https://github.com/lucolvin/public-pics-hosting/blob/main/final_proj_2023.png?raw=true",
        description: "Collaborated with Devin Kagak on the creation of a text based adventure game in python as the final project for COMP120 at Olivet Nazarene University.",
        link: "https://github.com/lucolvin/final_proj_fa23",
    },
    {
        img: "https://github.com/lucolvin/public-pics-hosting/blob/main/images/Launchpads.png?raw=true",
        description: "I created a Minecraft plugin while learning Java. It changes the slime block into a launchpad and integrates a config.yml file to allow user to change upward and forward momentum. It is implemented on https://www.mangomc.net/",
        link: "https://github.com/lucolvin/Launchpads",
    },
    {
        img: "https://github.com/lucolvin/public-pics-hosting/blob/main/Old_Site.png?raw=true",
        description: "This is my old website. It was my first try at creating a modern looking website that included a contact form.It was made with HTML, CSS, and Javascript.",
        link: "https://github.com/lucolvin/Personal-Website",
    },
    {
        img: "https://github.com/lucolvin/public-pics-hosting/blob/main/site_snippet.png?raw=true",
        description: "Created this very website. This was my first time using React and the Context API. It was made with HTML, CSS, and React.",
        link: "https://www.lukecolvin.me",
    },
    {
        img: "https://github.com/lucolvin/public-pics-hosting/blob/main/ccso.olivet.edu_alt.png?raw=true",
        description: "Created the new website for the Center for Cybersecurity Outreach at Olivet Nazarene University. Using Wordpress, HTML, and CSS.",
        link: "#",
    },

    // {
    //     img: "https://lore.com",
    //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    //     link: "#",
    // },
];

// not at all implemented yet. COME BACK LATER
// TODO - add this later
const contactConfig = {
    YOUR_EMAIL: "404: Email not found",
    YOUR_FONE: "",
    description: "Feel free to reach out to me and I'll respond ASAP!",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    // YOUR_SERVICE_ID: "service_ouxasjr",
    // YOUR_TEMPLATE_ID: "template_id",
    // YOUR_USER_ID: "user_id",
};

const socialProfiles = {
    github: "https://github.com/lucolvin",
    // facebook: "https://facebook.com",
    linkedin: "https://www.linkedin.com/in/lucolvin/",
    twitter: "https://twitter.com/CodebyLuke",
};
export {
    meta,
    dataabout,
    dataProjects,
    worktimeline,
    volunteering,
    school,
    skills,
    skillsLearning,
    services,
    introdata,
    contactConfig,
    socialProfiles,
    logotext,
};